/* You can add global styles to this file, and also import other style files */

@import "~ngx-toastr/toastr";

body {
  font-family: "Poppins", sans-serif !important;
}
.mat-h1,
.mat-headline,
.mat-typography h1 {
  margin-bottom: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: inherit;
  overflow: hidden;
}
// @media (min-width: 1441px) {
//   .container-fluid {
//     max-width: 1670px !important;
//   }
// }
.form-control,
textarea,
input {
  font-family: "Poppins", sans-serif !important;
}
select#event-category {
  font-family: "Poppins", sans-serif !important;
  color: #495057;
  opacity: 0.8;
}

// Sidebar
.sidebar {
  margin: 0;
  padding: 0;
  width: 270px;
  background-color: #fff;
  position: fixed;
  overflow: auto;
  border-right: 1px solid #efedee;
  height: 100%;
  box-shadow: 0px 0px 47px -10px #0d0d0d1f;
      top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;

}

.sidebar a {
  display: block;
  color: #1a1818;
  padding: 9px 30px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.sidebar a.active {
  background-color: #346ef6;
  color: white;
  margin-right: 5px;
  border-radius: 0 11px 11px 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
// .sidebar a img {
//   padding-right: 12px;
//   width: 30px;
// }
.logo img {
  width: 50%;
  /* margin-top: 10px; */
  height: 105px;
}
span.sidebar-icon {
  width: 40px;
  display: inline-block;
  text-align: center;
}
div.content {
  margin-left: 270px;
  padding: 15px 30px 52px;
  height: 100%;
  margin-top: 105px;
}

@media (max-width: 1367px) {
  .sidebar a {
    padding: 6px 30px;
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content,
  .header {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
/* ===========================================
Start modal common css
============================================*/
.modal.fade {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.modal-header {
  border-bottom: 0 !important;
  z-index: 1;
}
.modal-dialog {
  min-width: fit-content;
  max-width: 600px;
  box-shadow: 0 50px 99px rgba(0, 0, 0, 0.1);
}
.modal-content {
  width: 517px;
  min-height: 320px;
  // height: 395px;
  border-radius: 17px;
}
.modal-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: -3rem;
  p {
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;
  }
  img{
    margin-top: 41px;
  }
}
.modal-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 30px;
  .btn {
    width: 130px;
    height: 50px;
    border-radius: 11px;
    color: #fff;
  }
  .btn-cancel {
    background: #9399b1;
  }
  .btn-confirm {
    background: #f3728b;
  }
  .btn-blue {
    background: #346ef6;
  }
}
.modal-btn.text-center {
  justify-content: center;
}
.modal-header .close {
  padding: 1rem;
  margin: 0rem 0rem -1rem auto;
  border: 1px solid #dedede;
  border-radius: 50%;
  font-weight: 300;
  font-size: 35px;
  color: #1a1818;
  padding: 0px;
  width: 40px;
  height: 40px;
  outline: none;
}
// End modal common css

/*==============================================
 Start Careers and Events page common css
 ==============================================*/
// .tab-content,
// table.table.mt-2 {
//   width: 1107px;
// }
@media (max-width: 1367px) {
  .tab-content,
  table.table.mt-2 {
    width: 1025px;
  }
}
.skill-tabs {
  img.title-thumb {
    width: 44px;
    height: 40px;
    border-radius: 11px;
    object-fit: contain;
    margin-right: 10px;
  }
  .table thead th {
    border-bottom: 1px solid #efedee;
    margin-top: 10px;
  }
  th {
    font-size: 14px;
    font-weight: 500;
    color: #9399b1;
    border: 0;
  }
  td {
    font-size: 12px;
    font-weight: 500;
    color: #1a1818;
    border: 0;
  }
  p {
    margin-bottom: 0;
  }

  a.nav-link.active {
    min-width: 140px;
    height: 40px;
    text-align: center;
    border-radius: 11px;
    background: #346ef6;
    margin-right: 15px;
    color: #fff;
    margin-bottom: 5px;
  }
  a.nav-link {
    min-width: 140px;
    height: 40px;
    line-height: 25px;
    text-align: center;
    border-radius: 11px;
    background: #f8f7fb;
    margin-right: 15px;
    color: #1a1818;
    margin-bottom: 5px;
  }
  button.btn.btn-yellow {
    font-size: 12px;
    font-weight: 500;
    background: #fff8ec;
    border-radius: 11px;
    height: 34px;
    color: #ff9f0a;
  }
  button.btn.btn-active {
    font-size: 12px;
    font-weight: 500;
    background: #ebfff0;
    border-radius: 11px;
    height: 34px;
    color: #34c759;
    width: 80px;
  }
  button.btn.btn-expired {
    font-size: 12px;
    font-weight: 500;
    background: #ffebeb;
    border-radius: 11px;
    height: 34px;
    color: #f3728b;
    width: 80px;
  }
  tr th:last-child {
    width: 120px;
  }
  td span:nth-child(2) {
    margin-left: 20px;
    margin-right: 20px;
  }
  td span img {
    cursor: pointer;
  }
  .skill-tab-right {
    position: relative;
  }
  .btn.btn-create {
    position: absolute;
    right: 0;
    background: #1a1818;
    color: #fff;
    border-radius: 11px;
    height: 40px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
}

.btn-import {
  position: absolute;
  top: 121px;
  left: 341px;
  background: #1a1818;
  color: #fff;
  border-radius: 11px;
  height: 40px;
  display: flex;
  align-items: center;
}

// End Careers and Events common css

/*============================================
Start Views header
============================================*/
.views-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    min-width: 120px;
    max-width: 205px;
    text-align: center;
  }
  .btn.btn-blue {
    background: #346ef6;
    color: #fff;
  }
  .btn.btn-red {
    background: #ffebeb;
    color: #f3728b;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  }
}
h1 {
  margin-bottom: 0;
}
// End Views header

// CkeEditor css
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-editor__main > .ck-editor__editable {
  border: 0;
  border-color: transparent;
  background: #f8f7fb;
  margin-top: 7px;
  min-height: 250px;
  border-radius: 8px;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border: 0;
  box-shadow: none;
  border: 8px;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border: 0px;
}

select option[data-default] {
  color: #888;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word !important;
}

.breadcrmb {
  font-size: 16px !important;
}

// Responsivness

.card {
  margin-bottom: 20px;
  border: 0px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);
}

.card label {
  color: #495057;
  font-weight: 500;
}

.card-header {
  background: #ffffff;
  padding: 14px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header .card-title {
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  color: #495057;
  font-family: "Poppins",
      sans-serif !important;
}

.card-body .sub-title {
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  color: #495057;
  font-family: "Poppins", sans-serif !important;
}

.card-body {
  padding: 8px 18px;
}

@media (max-width: 1367px) {
  .news-list .news-card h3 {
    font-size: 20px;
    line-height: 29px;
  }
}
@media (min-width: 1680px) and (max-width: 3840px) {
  .header {
    border-right: 1px solid #efedee;
  }
  div.content {
    border-right: 1px solid #efedee;
    border-bottom: 1px solid #efedee;
  }
  .sidebar {
    border-bottom: 1px solid #efedee;
    border-left: 1px solid #efedee;
  }
}

@media (max-width: 1281px) {
  .header {
    max-width: 1015px !important;
  }
  div.content {
    max-width: 995px !important;
  }
  .tab-content,
  table.table.mt-2 {
    max-width: 955px;
  }
  .total-count {
    max-width: 230px !important;
  }
  .news-list .news-card {
    max-width: 33% !important;
    margin-right: 5px !important;
  }
  .partner-lists .p-list {
    margin-right: 18px !important;
  }
  .news-list .news-card span.new-date {
    padding-right: 5px !important;
  }
}

/* ====================================
Breadcrumb css
======================================*/
.breadcrmb {
  span.breadcrumb-link {
    font-size: 16px;
    color: #9399b1;
    cursor: pointer;
  }
  img {
    padding: 0 7px;
  }
}

// end breadcrumb css

// Paginatoin
.page-link {
  border: 0;
}
.page-item.active .page-link {
  background-color: #1a1818;
  border-radius: 7px;
  width: 35px;
  height: 35px;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.page-item .page-link {
  background-color: #fff;
  border-radius: 7px;
  width: 35px;
  height: 35px;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  color: #1a1818;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination-prev.page-item a,
.pagination-next.page-item a {
  font-size: 35px;
  line-height: 17px;
}
.pagination-prev.page-item .page-link,
.pagination-next.page-item .page-link {
  background-color: #f8f7fb;
}

// Search form css
.search-form {
  position: fixed;
  top: 24px;
  right: 460px;
  z-index: 10;
  .form-group {
    position: relative;
    margin-bottom: 0;
    img {
      position: absolute;
      left: 15px;
      top: 19px;
    }
  }
  .form-control {
    height: 60px;
    border-radius: 11px;
    background: #f8f7fb;
    width: 350px;
    border: 0;
    padding-left: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #9399b1;
  }
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  display: inline-block;
  padding: 0.15rem 0.5rem;
  margin-top: 5px;
}

// Time picker
.timepicker {
  outline: none !important;
  .timepicker__header {
    background-color: #346ef6 !important;
  }

  .clock-face__clock-hand {
    background-color: #346ef6 !important;

    &::after {
      background-color: #346ef6 !important;
    }

    &::before {
      background-color: #346ef6 !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: #346ef6 !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: #346ef6 !important;
  }
}

// ngx bootstrap daterange picker
.theme-green .bs-datepicker-head {
  background-color: #346ef6 !important;
}

@media (min-width: 1441px) {
  .search-form {
    right: 620px !important;
  }
}

@media (max-width: 1281px) {
  .edit-form .form-top-sec .title-category {
    width: 55% !important;
    margin-left: 1%;
  }
}
.skill-tab-left{
  width:100%
}
.edit-form .form-top-sec{
  align-items: center;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.modal-body .reset-txt{
padding-top: 43px;
padding-bottom: 30px;
}

.mat-form-field-invalid{
  border: none !important;
}

.mat-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0px;
  width: 180px;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
}